<template>
  <SideBar v-on="$listeners">
    <template #title>
      {{ _currentTitle }}
    </template>

    <template #body>
      <section class="main-section" data-test-form-new-action-plan>
        <div
          v-if="locked"
          data-test-action-plans-blocked
          class="blocked"
          @click="callUpdateYourPackage()"
        >
          <div class="glass"></div>
          <div class="blocked-icon">
            <i class="fi fi-sr-lock"></i>
          </div>
        </div>

        <v-form ref="form" v-model="valid" @submit.prevent>
          <!-- about -->
          <section data-test-form-action-plan-about>
            <div class="content-section-form">
              <div class="content-section-form-spacing">
                <!-- title -->
                <v-text-field
                  ref="title"
                  v-model="form.title"
                  data-test-action-plan-title
                  class="custom-input"
                  color="black"
                  hide-details="auto"
                  :label="$t('actionPlan.sideBar.form.about.title')"
                  :rules="_rules"
                  outlined
                  dense
                  @update:error="validEdit = !$event"
                  @keyup="handleEdit()"
                ></v-text-field>
              </div>

              <!-- description -->
              <v-textarea
                v-model="form.description"
                data-test-action-plan-description
                class="custom-input"
                color="black"
                hide-details="auto"
                rows="2"
                :label="$t('actionPlan.sideBar.form.about.description')"
                :rules="_rules"
                auto-grow
                outlined
                @keyup="handleEdit()"
              ></v-textarea>
            </div>
          </section>

          <!-- public -->
          <section data-test-form-action-plan-public>
            <div class="content-section-header">
              <span
                class="content-section-header-title"
                v-text="$t('actionPlan.sideBar.form.public.label')"
              ></span>
            </div>

            <div class="content-section-form">
              <div
                class="content-section-form-label"
                v-text="$t('actionPlan.sideBar.form.public.linkLabel')"
              ></div>

              <!-- switch link group -->
              <div class="d-flex content-section-form-spacing">
                <v-btn
                  class="text-none mr-1 rounded-lg"
                  height="32"
                  :disabled="editMode && !linkGroup"
                  :color="linkGroup ? 'primary' : 'grey'"
                  :dark="linkGroup && !editMode"
                  :outlined="!linkGroup"
                  depressed
                  @click="handleLink(true)"
                >
                  <span
                    class="bttn-label"
                    v-text="$t('actionPlan.sideBar.form.public.yes')"
                  ></span>
                </v-btn>

                <v-btn
                  active-class="primary white--text"
                  class="text-none ml-1 rounded-lg"
                  height="32"
                  :disabled="!getIsAdmin || (editMode && linkGroup)"
                  :color="!linkGroup ? 'primary' : 'grey'"
                  :dark="!linkGroup && !editMode"
                  :outlined="linkGroup"
                  depressed
                  @click="handleLink(false)"
                >
                  <span
                    class="bttn-label"
                    v-text="$t('actionPlan.sideBar.form.public.no')"
                  ></span>
                </v-btn>
              </div>
              <!-- click:clear -->
              <ItemSelector
                v-if="linkGroup"
                ref="ItemSelector"
                data-test-action-plan-group
                :menu-config="{ attach: true }"
                :readonly="editMode"
                :menu-options="_groupSelectorOptions"
                :current-value="selectedGroup"
                :highlight-quantity="8"
                class="custom-input"
                :input-config="{
                  label: $t('actionPlan.sideBar.form.public.group'),
                  clearable: !editMode,
                  rules: _rules,
                }"
                :persistent="true"
                :local-search="true"
                :watch-current="true"
                :multiple="false"
                @update:item="handleGroupSelect($event)"
              ></ItemSelector>
              <v-card
                v-else
                color="neutral9"
                class="card-link"
                height="40"
                flat
              >
                <span
                  class="content-section-form-label neutral5--text"
                  v-text="$t('actionPlan.sideBar.form.public.cardLabel')"
                ></span>
              </v-card>
            </div>
          </section>

          <!-- config -->
          <section data-test-form-action-plan-config>
            <div class="content-section-header">
              <span
                class="content-section-header-title"
                v-text="$t('actionPlan.sideBar.form.config.label')"
              ></span>
            </div>

            <div class="content-section-form">
              <ItemSelector
                ref="type"
                data-test-action-plan-type
                class="custom-input"
                :menu-options="_typeSelectorOptions"
                :current-value="selectedType"
                :menu-config="{ attach: true, showTabs: false }"
                :input-config="{
                  label: $t('actionPlan.sideBar.form.config.type'),
                  rules: _rules,
                  showAvatar: false,
                  closeOnSelect: true,
                }"
                :persistent="true"
                :local-search="true"
                :watch-current="true"
                :multiple="false"
                :readonly="editMode"
                :highlight-quantity="8"
                @update:item="handleTypeSelect($event)"
              ></ItemSelector>
            </div>
          </section>

          <!-- metrics -->
          <section
            v-if="!!form.type && form.type !== 'generic'"
            data-test-form-action-plan-metrics
          >
            <div class="content-section-header">
              <span
                class="content-section-header-title"
                v-text="$t('actionPlan.sideBar.form.metrics.label')"
              ></span>
            </div>

            <div class="content-section-form">
              <v-select
                ref="metric"
                v-model="form.metric"
                data-test-action-plan-metric
                class="custom-input"
                color="black"
                item-text="label"
                hide-details="auto"
                :label="$t('actionPlan.sideBar.form.metrics.metric')"
                :no-data-text="$t('actionPlan.sideBar.form.noData')"
                :items="indicators"
                :rules="_ruleMetric"
                :disabled="editMode"
                return-object
                outlined
                dense
              >
                <template #item="{ item, attrs, on }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-avatar size="22">
                      <v-img :src="item.image" contain></v-img>
                    </v-list-item-avatar>

                    <v-list-item-title>
                      <span
                        class="select-item-label"
                        v-text="item.label"
                      ></span>

                      <span
                        class="select-item-score"
                        :style="`color: ${handleScoreColor(item.score, item)}`"
                      >
                        {{ item.score }}
                        {{ isMetricPercentType(item) ? '%' : '' }}
                      </span>
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </div>
          </section>

          <!-- objective -->
          <section
            v-if="!!form.type && form.type !== 'generic'"
            data-test-form-action-plan-objective
          >
            <div class="content-section-header">
              <span
                class="content-section-header-title"
                v-text="$t('actionPlan.sideBar.form.objective.label')"
              ></span>
            </div>

            <div class="content-section-form flex-column">
              <!-- progress float note -->
              <div
                class="slider-objective nowrap"
                :style="{
                  'margin-left': _progressWidth,
                }"
              >
                <span
                  class="slider-objective-score"
                  :style="{
                    color: handleScoreColor(slider.value, form.metric),
                  }"
                >
                  {{ slider.value }}
                  <span v-if="isMetricPercentType(form.metric)">%</span>
                </span>

                <span
                  class="slider-objective-label"
                  :style="{
                    color: handleScoreColor(slider.value, form.metric),
                  }"
                  v-text="$t('actionPlan.sideBar.form.objective.objective')"
                ></span>
              </div>

              <div
                v-if="!_isDecreasing"
                class="slider-content d-flex align-center"
              >
                <!-- current space -->
                <div
                  class="d-flex progress-label"
                  :style="`width: ${_fillWidth}%`"
                >
                  <v-sheet
                    height="6px"
                    width="100%"
                    class="rounded-l-lg"
                    color="gray3"
                  ></v-sheet>

                  <!-- current note -->
                  <div v-if="!!form.metric" class="slider-current nowrap">
                    <span class="slider-current-score">
                      {{ form.metric.score }}
                      <span v-if="isMetricPercentType(form.metric)">%</span>
                    </span>

                    <span
                      class="slider-current-label"
                      v-text="$t('actionPlan.sideBar.form.objective.current')"
                    ></span>
                  </div>
                </div>

                <!-- progress space -->
                <div
                  class="d-flex progress-label"
                  :style="`width: ${100 - _fillWidth}%`"
                >
                  <!-- progress slider -->
                  <input
                    id="myRange"
                    v-model="slider.value"
                    class="slider"
                    type="range"
                    :step="slider.step"
                    :min="slider.min"
                    :max="slider.max"
                    :disabled="editMode"
                  />
                </div>
              </div>

              <div
                v-else
                class="slider-content slider-content-drecreasing d-flex align-center"
              >
                <!-- progress space -->
                <div
                  class="d-flex progress-label"
                  :style="`width: ${_fillWidth}%`"
                >
                  <!-- progress slider -->
                  <input
                    id="myRange"
                    v-model="slider.value"
                    class="slider"
                    type="range"
                    :step="slider.step"
                    :min="slider.min"
                    :max="slider.max"
                    :disabled="editMode"
                  />
                </div>

                <!-- current space -->
                <div
                  class="d-flex progress-label"
                  :style="`width: ${100 - _fillWidth}%`"
                >
                  <v-sheet height="6px" width="100%" color="gray3"></v-sheet>

                  <!-- current note -->
                  <div v-if="!!form.metric" class="slider-current nowrap">
                    <span class="slider-current-score">
                      {{ form.metric.score }}
                      <span v-if="isMetricPercentType(form.metric)">%</span>
                    </span>

                    <span
                      class="slider-current-label"
                      v-text="$t('actionPlan.sideBar.form.objective.current')"
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <TaskManager
            ref="tasks"
            data-test-form-action-plan-task
            :action-plan-i-d="planData ? planData.id : null"
            :tasks="form.tasks"
            :people="people"
            multiple-people
            :edit-mode="editMode"
            v-on="$listeners"
            @submit="form.tasks = $event"
          ></TaskManager>

          <!-- deadline -->
          <section data-test-form-action-plan-deadline>
            <div class="content-section-header">
              <span
                class="content-section-header-title"
                v-text="$t('actionPlan.sideBar.form.deadline.label')"
              ></span>
            </div>

            <div class="content-section-form">
              <v-row no-gutters>
                <v-col cols="12" sm="8" md="6" lg="5" xl="4">
                  <v-dialog ref="dialog" v-model="datePick" width="290px">
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="dueDate"
                        data-test-action-plan-deadline
                        v-bind="attrs"
                        class="custom-input"
                        color="black"
                        hide-details="auto"
                        :label="$t('actionPlan.sideBar.form.deadline.dueDate')"
                        :rules="_rules"
                        :disabled="editMode"
                        outlined
                        readonly
                        dense
                        v-on="on"
                      >
                        <template #append>
                          <v-icon class="mt-1" small>fi fi-rr-calendar</v-icon>
                        </template>
                      </v-text-field>
                    </template>

                    <v-date-picker
                      v-model="form.dueDate"
                      data-test-action-plan-deadline-date-picker
                      :min="minDate"
                      :locale="$t('locale')"
                      color="primary"
                      scrollable
                      no-title
                      @input="
                        handleInputDate($event)
                        datePick = false
                      "
                    ></v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
            </div>
          </section>
        </v-form>
      </section>
    </template>

    <!-- footer -->
    <template v-if="!locked" #footer>
      <v-row justify="space-between">
        <v-col cols="12" sm="6">
          <v-btn
            v-if="canCreateNewTemplate"
            color="neutral5"
            class="text-none"
            :block="$vuetify.breakpoint.smAndDown"
            text
            data-test-save-action-plan-template
            @click="saveTemplate()"
          >
            <span
              class="bttn-label"
              data-test-action-plan-save-template
              v-text="$t('actionPlan.sideBar.form.saveTemplate')"
            ></span>

            <v-btn v-if="savedTemplate" class="ml-2 relative" small icon>
              <v-icon color="success" small> fi-rr-circle</v-icon>
              <v-icon
                data-test-save-action-plan-save-template-success
                class="absolute"
                color="success"
                x-small
              >
                fi-rr-check
              </v-icon>
            </v-btn>
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6" class="d-flex justify-end">
          <v-btn
            v-if="canCreateNewPlan && !editMode"
            data-test-btn-new-plan
            class="text-none px-8"
            color="primary"
            :block="$vuetify.breakpoint.smAndDown"
            depressed
            @click="newPlan()"
          >
            <span
              class="bttn-label"
              v-text="$t('actionPlan.sideBar.form.createPlan')"
            ></span>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </SideBar>
</template>

<script>
import SideBar from '@/components/SideBar/SideBar.vue'
import TaskManager from './TaskManager/TaskManager.vue'

import variables from '@/styles/abstracts/_colors.module.scss'

import { mapState, mapGetters } from 'vuex'
const moment = require('moment')

export default {
  components: {
    SideBar,
    TaskManager,
  },

  props: {
    template: null,
    planData: null,
    groups: {
      type: Array,
      default: () => [],
    },
    indicators: {
      type: Array,
      default: () => [],
    },
    people: {
      type: Array,
      default: () => [],
    },
    tasks: {
      type: Array,
      default: () => [],
    },
    locked: Boolean,
    canCreateNewTemplate: {
      type: Boolean,
      default: true,
    },
    canCreateNewPlan: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      selectedGroup: null,
      selectedType: null,

      valid: false,
      interval: null,
      validEdit: true,
      savedTemplate: false,
      linkGroup: true,
      editMode: false,
      querySearch: '',
      types: [
        {
          id: 'generic',
          label: this.$t('actionPlan.sideBar.form.config.types.generic'),
        },
        {
          id: 'climate',
          label: this.$t('actionPlan.sideBar.form.config.types.climate'),
        },
        {
          id: 'culture',
          label: this.$t('actionPlan.sideBar.form.config.types.culture'),
        },
        {
          id: 'participation',
          label: this.$t('actionPlan.sideBar.form.config.types.participation'),
        },
      ],
      slider: {
        value: 0,
        min: 0,
        max: 10,
        step: 0.1,
      },
      datePick: false,
      dueDate: '',
      minDate: new Date().toISOString().slice(0, 10),
      auxDate: '',
      form: {
        title: '',
        description: '',
        group: '',
        metric: '',
        type: '',
        tasks: '',
        dueDate: '',
      },
    }
  },

  computed: {
    ...mapState(['groupId']),
    ...mapGetters(['getIsAdmin']),

    _groupSelectorOptions() {
      return [
        {
          type: 'treeview',
          value: 'groups',
          label: 'Grupos',
          items: this.groups,
        },
      ]
    },
    _typeSelectorOptions() {
      return [
        {
          type: 'listview',
          value: 'types',
          label: this.$t('actionPlan.sideBar.form.config.type'),
          items: this.types,
        },
      ]
    },

    _currentTitle() {
      if (!this.editMode) {
        return this.$t('actionPlan.sideBar.titleNewPlan')
      }

      return this.$t('actionPlan.sideBar.titleEditPlan')
    },

    _rules() {
      return [
        (v) => {
          const requiredLabel = this.$t('actionPlan.sideBar.form.required')

          if (v instanceof Array && !v.length) {
            return requiredLabel
          }

          return !!v || requiredLabel
        },
      ]
    },

    _ruleMetric() {
      return [
        (v) =>
          this.hasDataContent(v) || this.$t('actionPlan.sideBar.form.required'),
      ]
    },

    _fillWidth() {
      if (!this.form.metric) {
        return 0
      }

      const { max, min } = this.slider

      if (this._isDecreasing) {
        return max
      }

      const isHundredScore = this.isMetricHundredType(this.form.metric)
      const limiterMinimum = isHundredScore ? 100 + min : min
      const limiterMaximum = isHundredScore ? 200 : max

      return (limiterMinimum * 100) / limiterMaximum
    },

    _progressWidth() {
      return this.handleMarginLeftLabelSlider(
        this.slider.value,
        this.form.metric
      )
    },

    _isDecreasing() {
      return this.getMetricIsDecreasing(this.form?.metric?.indicatorID)
    },
  },

  watch: {
    groups() {
      this.setDefaultGroup()
    },

    'form.metric': {
      handler(metric) {
        this.handleSlideFromMetric(metric)
      },
    },

    indicators: {
      handler(v) {
        this.handleIndicator(v)
      },
      immediate: true,
      deep: true,
    },

    tasks(v) {
      this.form.tasks = v
    },
  },

  created() {
    if (this.hasDataContent(this.template)) {
      this.startTemplate()
    }

    if (this.hasDataContent(this.planData)) {
      this.startPlan()
    }
  },

  mounted() {
    this.$emit('get-groups')

    this.startCurrentGroup()
    this.setDefaultGroup()
  },

  methods: {
    getMetricIsDecreasing(indicatorID) {
      return ['unfavorableCulture'].includes(indicatorID)
    },

    handleSlideFromMetric(metric) {
      if (!metric) return

      const score = Number(metric.score)
      const isHundredScore = this.isMetricHundredType(metric)
      const isPercentScore = this.isMetricPercentType(metric)

      const isDecreasing = this.getMetricIsDecreasing(metric.indicatorID)

      const value = this.planData?.progress?.goal
      this.slider.value = (value ?? score) || 0

      this.slider.min =
        (isDecreasing ? null : score) ?? (isHundredScore ? -100 : 0)
      this.slider.max =
        (isDecreasing ? score : null) ??
        (isPercentScore || isHundredScore ? 100 : 10)

      const decimalPart = (Math.floor(score * 100) % 100) % 10
      this.slider.step = decimalPart > 0 ? 0.01 : 0.1
    },

    hasDataContent(data) {
      return !!data && Object.keys(data).length > 0
    },

    handlerSelectedGroup(group) {
      this.selectedGroup = {
        data: [group],
        origin: 'groups',
      }

      this.$refs.ItemSelector.setCurrentValue({ ...this.selectedGroup })
    },
    startCurrentGroup() {
      if (!this.editMode) {
        return
      }

      if (!this.planData?.group?.id) {
        this.handleLink(false)
        return
      }

      this.planData.group.label = this.planData.group?.name
      this.handlerSelectedGroup(this.planData.group)
    },
    setDefaultGroup() {
      if (!(Array.isArray(this.groups) && this.groups.length)) {
        return
      }

      const selectedGroupID = this.groupId || null
      const group = this.groups.find((group) => group.id === selectedGroupID)

      if (!group) {
        return
      }

      this.handlerSelectedGroup(group)
    },

    setSelectedType(type) {
      const data = [type].filter(Boolean)
      this.selectedType = {
        data,
        origin: 'types',
      }
    },

    handleGroupSelect(group) {
      this.form.group = group
    },
    handleTypeSelect(type) {
      this.setSelectedType(type)

      this.form.type = type.id

      this.form.metric = ''
      this.slider.value = 0
      this.getIndicators()
    },
    // submit create a new plan
    async newPlan() {
      const form = this.$refs.form.validate()
      const tasks = this.$refs.tasks.validate()

      if (!form || !tasks) return
      const tasksFormated = this.form.tasks.map((item) => ({
        ...item,
        assignedTo: item.assignedTo
          .map((e) => (e.id ? { id: e.id } : null))
          .filter(Boolean),
      }))

      let payload = {
        title: this.form.title,
        description: this.form.description,
        group: this.form.group ? { id: this.form.group.id } : null,
        metric: this.form.metric ? { id: this.form.metric.indicatorID } : null,
        progress:
          this.slider.value > 0
            ? { goal: this.slider.value, initial: this.form.metric.score || 0 }
            : {},
        tasks: tasksFormated,
        type: this.form.type,
        dueDate: this.form.dueDate,
      }

      this.$emit('new-plan', payload)
    },

    // submit edit current plan
    async editPlan() {
      if (!this.$refs.title.valid) return

      const payload = {
        id: this.planData.id,
        body: {
          title: this.form.title,
          description: this.form.description,
        },
      }

      this.$emit('edit-plan', payload)
    },

    // handle input title or description for edit
    handleEdit() {
      if (!this.editMode) {
        return
      }

      if (this.interval) {
        clearTimeout(this.interval)
      }

      this.interval = setTimeout(() => {
        this.editPlan()
      }, 500)
    },

    async saveTemplate() {
      const isTitleValid = this.$refs.title.validate()
      const isTypeValid = this.$refs.type.validate()
      const areTasksValid = this.$refs.tasks.validate()
      const isMetricValid =
        !this.form.type || this.form.type === 'generic'
          ? true
          : this.$refs.metric.validate()

      if (!isTitleValid) {
        this.form.title = null
      }

      if (!isTypeValid) {
        this.form.type = null
      }

      if (!isMetricValid) {
        this.form.metric = null
      }

      if (!isTitleValid || !isTypeValid || !isMetricValid || !areTasksValid) {
        return
      }

      const formattedTasks = this.form.tasks.map((item) => {
        const { id, ...payload } = item

        return {
          ...payload,
          assignedTo: item.assignedTo
            .map((person) => (person.id ? { id: person.id } : null))
            .filter(Boolean),
        }
      })

      const payload = {
        title: this.form.title,
        description: this.form.description,
        metric: this.form.metric ? { id: this.form.metric.indicatorID } : null,
        tasks: formattedTasks,
        type: this.form.type,
      }

      this.$emit('save-template', payload)
    },

    // start form with template data
    startTemplate() {
      this.form = {
        title: this.template.title,
        description: this.template.description,
        group: this.template.group,
        metric: this.template.metric,
        type: this.template.type,
        tasks: this.template.tasks,
        dueDate: this.template.dueDate,
      }

      const selectedType = this.types.find(
        (type) => type.id === this.template.type
      )
      this.setSelectedType(selectedType)

      this.dueDate = this.formatDate(this.template.dueDate)

      this.getIndicators()
    },

    // start plan with edit plan data
    startPlan() {
      this.editMode = this.planData.editMode || false

      this.form = {
        title: this.planData.title,
        description: this.planData.description,
        group: this.planData.group,
        metric: {
          ...this.planData.metric,
          indicatorID: this.planData.metric.id,
          score: this.planData.progress.current,
        },
        type: this.planData.type,
        tasks: this.planData.tasks,
        dueDate: this.planData.dueDate,
      }

      const selectedType = this.types.find(
        (type) => type.id === this.planData.type
      )
      this.setSelectedType(selectedType)

      this.slider.value = this.planData.progress.goal
      this.dueDate = this.formatDate(this.planData.dueDate)

      this.getIndicators()
    },

    // get indicators by type and group
    async getIndicators() {
      if (!this.form.type || this.form.type === 'generic') return

      const payload = {
        type: this.form.type,
        groupID: this.form.group ? this.form.group.id : '',
      }

      this.$emit('get-indicators', payload)
    },
    handleMarginLeftLabelSlider(score, metric) {
      score = Number(score)

      const isHundredScore = this.isMetricHundredType(metric)
      const isPercentScore = this.isMetricPercentType(metric)

      let calculatedScore = isPercentScore ? score : score * 10
      if (isHundredScore) {
        calculatedScore = ((100 + score) * 100) / 200
      }

      if (calculatedScore >= 90) {
        return '90%'
      }

      if (calculatedScore > 0) {
        return `calc(${calculatedScore}% - 8px)`
      }

      return `${calculatedScore}%`
    },
    // color of score metric
    handleScoreColor(score, metric) {
      const isHundredMetric =
        this.isMetricHundredType(metric) || this.isMetricPercentType(metric)

      const BELOW_MIDDLE = isHundredMetric ? 50 : 5
      const PAST_MIDDLE = isHundredMetric ? 75 : 7.5

      const isDecreasing = this.getMetricIsDecreasing(metric.indicatorID)

      if (score < BELOW_MIDDLE) {
        return isDecreasing ? variables.greenColor : variables.redColor2
      }

      if (score >= BELOW_MIDDLE && score < PAST_MIDDLE) {
        return variables.yellowColor1
      }

      if (score >= PAST_MIDDLE) {
        return isDecreasing ? variables.redColor2 : variables.greenColor
      }
    },

    isMetricPercentType(metric) {
      if (!metric) {
        return false
      }

      return (
        ['maturityLevel', 'culturalAlignment', 'unfavorableCulture'].includes(
          metric.indicatorID
        ) || this.form.type === 'participation'
      )
    },

    isMetricHundredType(metric) {
      if (!metric) {
        return false
      }

      return ['enps', 'lnps'].includes(metric.indicatorID)
    },

    // change link group
    handleLink(value) {
      if (!this.getIsAdmin && !value) return

      if (!value) {
        this.form.group = null
      }
      this.linkGroup = value
    },

    // set check saved template
    handleSavedTemplate() {
      this.savedTemplate = true
    },

    // set current indicator on form
    handleIndicator(indicators) {
      if (!this.form.metric?.id) {
        return
      }

      const indicator = indicators.find(
        (e) => e.indicatorID === this.form.metric.id
      )

      if (indicator) {
        this.form.metric = indicator
      }
    },

    // format date to DD / MM / YYYY
    formatDate(date) {
      if (!date) return null

      return moment(date).format(this.$t('actionPlan.sideBar.form.dateFormat'))
    },

    handleInputDate(value) {
      if (value !== this.auxDate) {
        this.auxDate = value
        this.dueDate = this.formatDate(value)
      } else {
        this.dueDate = ''
        this.auxDate = ''
        this.form.dueDate = ''
      }
    },

    callUpdateYourPackage() {
      this.$emit('close')

      this.$store.commit('openUpdateYourPackageDialog', 'action')
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
